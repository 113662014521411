import { useState, useEffect } from 'react';

import { getAppStorage } from '../utils/localStorage';
import { PropertyDefinitionSchema, useListPropertiesDefinitions } from '@/helpers/services/api';

interface PropertyInfo {
  [key: string]: PropertyDefinitionSchema;
}

export const usePropertiesInfo = () => {
  const { appId } = getAppStorage();

  const { data, isFetching: isPropertiesInfoFetching } = useListPropertiesDefinitions(appId);

  const [propertiesInfo, setPropertiesInfo] = useState<PropertyInfo>({});

  useEffect(() => {
    if (Array.isArray(data)) {
      const result: PropertyInfo = {};

      data.forEach(
        e =>
          (result[e.property_name] = {
            property_name: e.property_name,
            url: e.url,
            description: e.description,
            property_type: e.property_type,
            property_column_name: e.property_column_name,
            column_type: e.column_type,
            is_hidden: e?.is_hidden,
            in_dashboard: e?.in_dashboard,
            interaction_type: e?.interaction_type
          })
      );
      setPropertiesInfo(result);
    }
  }, [data]);

  const getPropertyInfo = (name: string) => propertiesInfo[name] || { description: '', url: '' };

  return { propertiesInfo, isPropertiesInfoFetching, getPropertyInfo };
};
