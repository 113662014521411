export const getPercentage = (number?: number, round?: boolean, defaultZero?: boolean) => {
  if (!number && number !== 0) {
    return defaultZero ? '0%' : 'N/A';
  } else {
    const percentagedNumber = (Number(number) * 100)?.toFixed(2);
    const isZero = +percentagedNumber % 1 === 0;
    const finalizedNumber = isZero || round ? Math.round(+percentagedNumber) : percentagedNumber;

    return `${finalizedNumber}%`;
  }
};
