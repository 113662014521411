import React, { useEffect, useState, RefObject } from 'react';

import { useRouter } from 'next/router';

import DiffViewer, { DiffMethod } from 'react-diff-viewer';

import { Stack } from '@mui/material';

import { StyledSkeleton, StyledText, StyledToggle, StyledToolTip } from '@/components/lib';
import { AnnotationCell } from '@/components/shared/SamplesAnnotationCell/SamplesAnnotationCell';

import {
  StepSchema,
  getSimilarityScoreApiV1ApplicationVersionsGetSimilarityScoreGet,
  InteractionSchema,
  ReadPropertyConfigSchema,
  SimilarityScore
} from '@/helpers/services/api';
import { getUserPreferences } from '@/helpers/utils/localStorage';

import { SamplesDetailsBodyPropertyType } from '../../../SampleDetailsDialogBody/SamplesDetailsBodyItem/SampleDetailsBodyProperties/SampleDetailsBodyProperties';

import {
  StyledAnnotationContentContainer,
  StyledContentBottomContainer,
  StyledContentContainer,
  StyledDiffContainer,
  StyledInfoRetrievalText,
  StyledPropColorText,
  StyledPropertyScoreContainer,
  StyledPropertyScoreNumberContainer,
  StyledSimilarityContainer,
  StyledTogglerContainer
} from './SampleDetailsDialogComparisonInteractionContent.styles';
import {
  StyledRowList,
  StyledSelectOption,
  StyledAnnotationCellContainer
} from '../SampleDetailsDialogComparisonInteraction.styles';
import { StyledAnnotationCellText } from '../../../SampleDetailsDialogHeader/SampleDetailsDialogHeader.styles';

import { routes } from '@/helpers/routes';

import { constants } from '../../sampleDetailsDialogComparison.constants';

interface SampleDetailsInteractionContentProps {
  steps: StepSchema[];
  selectedTag: string;
  metricType?: string;
  isSelectable: boolean;
  selectedVersion?: string;
  isVersionComparison?: boolean;
  interaction: InteractionSchema;
  versionCompareInteraction?: boolean;
  scrollRef: RefObject<HTMLDivElement | null>;
  allPropsList: SamplesDetailsBodyPropertyType[];
  dataToShow: { input?: string | null; output?: string | null };
  comparisonContent: { base: string; diff: string; show: boolean };
  comparedVersionData: { versionId?: number; interactionId?: string };
  differentByPropData?: ReadPropertyConfigSchema;
  initialVersion: { name?: string; id?: number; user_interaction_id?: string };
  setSelectedTag: (value: string) => void;
  handleScroll: (ref: RefObject<HTMLDivElement | null>) => void;
  setMainSelectedTag: React.Dispatch<React.SetStateAction<string>>;
  checkSectionData: (key: string) => { input?: string | null; output?: string | null };
  setComparisonContent: React.Dispatch<React.SetStateAction<{ base: string; diff: string; show: boolean }>>;
  updateAnnotation?: (interactionId: string, annotation: string, reason: string, isEstimated?: boolean) => void;
}

const { keys, similarityContent, score, diffTooltip, documentTitle } = constants;

export const SampleDetailsDialogComparisonInteractionContent = (props: SampleDetailsInteractionContentProps) => {
  const {
    steps,
    scrollRef,
    dataToShow,
    selectedTag,
    interaction,
    allPropsList,
    isSelectable,
    initialVersion,
    selectedVersion,
    comparisonContent,
    comparedVersionData,
    differentByPropData,
    versionCompareInteraction,
    isVersionComparison,
    handleScroll,
    setSelectedTag,
    checkSectionData,
    updateAnnotation,
    setMainSelectedTag,
    setComparisonContent,
    metricType
  } = props;

  const [similarity, setSimilarity] = useState<SimilarityScore | null>();
  const [isSimilarityLoading, setIsSimilarityLoading] = useState<boolean>();

  const { pathname } = useRouter();
  const { darkMode } = getUserPreferences();

  const isDiffOn = !!comparisonContent?.show;
  const isVersionsPage = pathname?.includes(routes?.config?.versions);
  const isMultiSection = selectedTag === keys.infoRetrieval || selectedTag === keys.history;
  const isDiffAvailable = comparisonContent?.base && comparisonContent?.diff && selectedTag === keys?.output;

  const multiSectionContent =
    selectedTag === keys.infoRetrieval
      ? Array?.isArray(interaction?.information_retrieval)
        ? interaction?.information_retrieval
        : []
      : Array?.isArray(interaction?.history)
        ? interaction?.history
        : [];

  const differentByPropValue = allPropsList.find(
    el => el.name === differentByPropData?.text_property && el.type === differentByPropData.kind
  )?.value;
  const formattedValue =
    typeof differentByPropValue === 'number' ? +differentByPropValue.toFixed(2) || 0 : differentByPropValue || 'N/A';

  const handleTagChange = (key: StepSchema) => {
    setSelectedTag(key?.id);
    setMainSelectedTag('step');
  };

  const handleShowDiff = (show: boolean) => {
    setComparisonContent({ ...comparisonContent, show: show });
  };

  const isShowDiffLineNumbers = () => {
    if (isDiffAvailable && isDiffOn) {
      const regex = new RegExp(/[0-9](.| )/);
      return (
        regex.test(comparisonContent.base) ||
        regex.test(comparisonContent.diff) ||
        comparisonContent.base === comparisonContent.diff
      );
    }
    return false;
  };

  const curSimilarityScore =
    metricType && metricType.includes('blue_1')
      ? Number(similarity?.blue_1_score)?.toFixed(2)
      : metricType && metricType.includes('rouge_1')
        ? Number(similarity?.rouge_1_score)?.toFixed(2)
        : Number(similarity?.score)?.toFixed(2);

  useEffect(() => {
    if (initialVersion?.user_interaction_id && comparedVersionData?.interactionId && isSelectable) {
      setIsSimilarityLoading(true);
      getSimilarityScoreApiV1ApplicationVersionsGetSimilarityScoreGet({
        interaction_user_id_1: initialVersion?.user_interaction_id,
        interaction_user_id_2: comparedVersionData?.interactionId,
        interaction_version_id_1: Number(initialVersion?.id),
        interaction_version_id_2: Number(comparedVersionData?.versionId)
      })
        .then(res => (typeof res?.score === 'number' ? setSimilarity(res) : setSimilarity(null)))
        .finally(() => setIsSimilarityLoading(false));
    }
  }, [comparedVersionData?.interactionId]);

  useEffect(() => {
    if (!isVersionsPage) {
      setComparisonContent({
        show: !!comparisonContent.show,
        base: !isSelectable ? similarityContent.content(dataToShow?.input, dataToShow?.output) : comparisonContent.base,
        diff:
          versionCompareInteraction || isSelectable
            ? similarityContent.content(dataToShow?.input, dataToShow?.output)
            : comparisonContent.diff
      });
    }
  }, [selectedTag, interaction]);

  useEffect(() => {
    if (isVersionsPage) {
      const curOutput = similarityContent.content(dataToShow?.input, dataToShow?.output);
      if (!isSelectable && comparisonContent.base !== curOutput) {
        setComparisonContent({
          show: !!comparisonContent.show,
          base: curOutput,
          diff: comparisonContent.diff
        });
      } else if (isSelectable && comparisonContent.diff !== curOutput) {
        setComparisonContent({
          show: !!comparisonContent.show,
          base: comparisonContent.base,
          diff: curOutput
        });
      }
    }
  }, [selectedTag, interaction, isVersionsPage, comparisonContent]);

  return (
    <>
      <StyledRowList>
        {[keys?.input, keys?.infoRetrieval, keys?.history, keys?.fullPrompt, keys?.output].map(key => (
          <StyledSelectOption
            key={key}
            data-testid={`oprion-${key}-${isSelectable ? selectedVersion : initialVersion.name}`}
            text={key}
            type="bodyBold"
            selected={key === selectedTag}
            onClick={() => setMainSelectedTag(key)}
            show={!!checkSectionData(key)?.output || !!checkSectionData(key)?.input}
          />
        ))}
        {steps?.map(key => (
          <StyledSelectOption
            show={true}
            type="bodyBold"
            key={key?.name}
            text={key?.name}
            selected={key?.id === selectedTag}
            onClick={handleTagChange.bind(null, key)}
          />
        ))}
        {isSimilarityLoading ? (
          <StyledSkeleton height="25px" width="142px" margin="0 0 0 auto" />
        ) : typeof similarity?.score === 'number' ? (
          <StyledToolTip
            text={similarityContent?.tooltip(similarity?.score, similarity?.blue_1_score, similarity?.rouge_1_score)}
          >
            <StyledSimilarityContainer>
              <StyledText text={similarityContent.similarityLabel} type="small" />
              <StyledText text={curSimilarityScore} type="bodyBold" />
            </StyledSimilarityContainer>
          </StyledToolTip>
        ) : (
          <></>
        )}
      </StyledRowList>
      <StyledContentContainer
        ref={scrollRef}
        onScroll={() => handleScroll(scrollRef)}
        is_sticky={Boolean(!differentByPropData).toString()}
      >
        {isDiffAvailable && isDiffOn ? (
          <StyledDiffContainer diff={isSelectable} isDarkMode={Boolean(darkMode).toString()}>
            <DiffViewer
              splitView={false}
              showDiffOnly={false}
              compareMethod={DiffMethod.WORDS}
              oldValue={comparisonContent?.base}
              newValue={comparisonContent?.diff}
              hideLineNumbers={isShowDiffLineNumbers()}
            />
          </StyledDiffContainer>
        ) : (
          <>
            {isMultiSection
              ? multiSectionContent?.map((el, index) => (
                  <Stack key={index}>
                    <StyledInfoRetrievalText text={documentTitle(index + 1)} type="tinyBold" />
                    <StyledInfoRetrievalText text={el.data} type="tiny" is_margin="true" />
                  </Stack>
                ))
              : similarityContent.content(dataToShow?.input, dataToShow?.output)}
          </>
        )}
        {(!differentByPropData || !differentByPropData?.condition) && (
          <StyledAnnotationContentContainer is_sticky="true" is_full_width={isDiffAvailable.toString()}>
            {isDiffAvailable ? (
              <StyledToolTip text={diffTooltip(isDiffOn)}>
                <StyledTogglerContainer>
                  <StyledToggle state={isDiffOn} setState={handleShowDiff} withText />
                </StyledTogglerContainer>
              </StyledToolTip>
            ) : (
              <></>
            )}
            {interaction?.annotation?.reason && (
              <StyledToolTip text={interaction?.annotation?.reason}>
                <StyledAnnotationCellContainer reason>
                  <StyledAnnotationCellText text={interaction?.annotation?.reason} type="small" />
                </StyledAnnotationCellContainer>
              </StyledToolTip>
            )}
            <StyledAnnotationCellContainer>
              {interaction?.user_interaction_id && (
                <AnnotationCell
                  disableTooltip
                  interactionId={interaction?.user_interaction_id}
                  annotation={interaction?.annotation}
                  updateAnnotation={updateAnnotation}
                  estAnnotation={interaction.estimated_annotation}
                  interactionVersionId={isVersionComparison ? interaction.application_version_id : undefined}
                />
              )}
            </StyledAnnotationCellContainer>
          </StyledAnnotationContentContainer>
        )}
      </StyledContentContainer>
      <StyledContentBottomContainer>
        {differentByPropData && !!differentByPropData?.condition && (
          <>
            <StyledPropertyScoreContainer>
              <StyledPropColorText text={`${differentByPropData.text_property} ${score}`} type="tinyBold" />
              <StyledPropertyScoreNumberContainer>
                <StyledPropColorText text={formattedValue} type="bodyBold" />
              </StyledPropertyScoreNumberContainer>
            </StyledPropertyScoreContainer>
            <StyledAnnotationContentContainer>
              {interaction?.annotation?.reason && (
                <StyledToolTip text={interaction?.annotation?.reason}>
                  <StyledAnnotationCellContainer reason>
                    <StyledAnnotationCellText text={interaction?.annotation?.reason} type="small" />
                  </StyledAnnotationCellContainer>
                </StyledToolTip>
              )}

              <StyledAnnotationCellContainer>
                {interaction?.user_interaction_id && (
                  <AnnotationCell
                    disableTooltip
                    interactionId={interaction?.user_interaction_id}
                    annotation={interaction?.annotation}
                    updateAnnotation={updateAnnotation}
                    estAnnotation={interaction.estimated_annotation}
                  />
                )}
              </StyledAnnotationCellContainer>
            </StyledAnnotationContentContainer>
          </>
        )}
      </StyledContentBottomContainer>
    </>
  );
};
