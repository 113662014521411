import React from 'react';

import { Popover, PopoverProps, styled } from '@mui/material';

export const StyledPopover = styled((props: PopoverProps) => (
  <Popover
    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    transformOrigin={{ vertical: 'top', horizontal: 'center' }}
    slotProps={{ paper: { style: { width: '466px', borderRadius: '16px', padding: '12px 0' } } }}
    {...props}
  />
))({});
