import React, { useState } from 'react';
import { useRouter } from 'next/router';

import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

import { StyledDropdown, StyledText, StyledToolTip } from '@/components/lib';

import {
  ApplicationVersionExtendedSchema,
  Operator,
  PerEnvApplicationVersionExtendedSchema
} from '@/helpers/services/api';
import { getPercentage } from '@/helpers/utils/getPercentage';
import { useScoreColor } from '@/helpers/hooks/useScoreColor';

import {
  StyledGapRow,
  StyledRowList
} from '../SampleDetailsDialogComparisonInteraction/SampleDetailsDialogComparisonInteraction.styles';
import {
  StyledDescriptionTitleText,
  StyledInfoIcon,
  StyledMenuItem,
  StyledNumberContainer,
  StyledRootMenu,
  StyledScoreTypeDropdownContainer
} from './SampleDetailsDialogComparisonInteractionHeader.styles';

import { constants } from '../sampleDetailsDialogComparison.constants';

const { scoreWithEst, score, noVersionDescription, numberOfInteractionText } = constants;

interface SampleDetailsDialogComparisonInteractionHeaderProps {
  version: string;
  isSelectable?: boolean;
  isBorderLeft?: boolean;
  selectedVersion?: string;
  versionsDropdownData: { name: string }[];
  initialVersion: { name?: string; id?: number; userInteractionId?: string };
  versionsExtendedList: ApplicationVersionExtendedSchema[];
  includeEstScore: boolean;
  setIncludeEstScore: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedVersion: (value: string) => void;
  pageCount: number;
  handleInitialVersionChange: (value: string) => void;
}

export const SampleDetailsDialogComparisonInteractionHeader = (
  props: SampleDetailsDialogComparisonInteractionHeaderProps
) => {
  const {
    version,
    isSelectable,
    initialVersion,
    selectedVersion,
    versionsDropdownData,
    isBorderLeft,
    versionsExtendedList,
    includeEstScore,
    pageCount,
    setIncludeEstScore,
    setSelectedVersion,
    handleInitialVersionChange
  } = props;

  const { query } = useRouter();

  const [anchorElRootMenu, setAnchorElRootMenu] = useState<HTMLElement | null>(null);

  const curVersionName = isSelectable ? selectedVersion : initialVersion?.name;

  const handleScoreChange = (value: boolean) => {
    setIncludeEstScore(value);
    setAnchorElRootMenu(null);
  };

  const handleOpenRootMenu = (event: React.MouseEvent) => setAnchorElRootMenu(event.currentTarget as HTMLElement);

  const curVersionFromExtendedList = versionsExtendedList.find(el => el.name === curVersionName);
  const curSelectedVersionEnvData = curVersionFromExtendedList
    ? (curVersionFromExtendedList[
        (query.env as string)?.toLowerCase() as keyof typeof curVersionFromExtendedList
      ] as PerEnvApplicationVersionExtendedSchema)
    : ({} as PerEnvApplicationVersionExtendedSchema);

  const scoreColor = useScoreColor(curSelectedVersionEnvData?.score, Operator['GE'], 0.5);

  return (
    <StyledGapRow is_border_left={isBorderLeft ? 'true' : 'false'}>
      <StyledToolTip text={curVersionName}>
        <StyledRowList>
          <StyledText text={version} type="bodyBold" />
          {!isSelectable ? (
            <StyledDropdown
              value={initialVersion?.name}
              data={versionsDropdownData}
              setValue={handleInitialVersionChange}
            />
          ) : (
            <StyledDropdown value={selectedVersion} data={versionsDropdownData} setValue={setSelectedVersion} />
          )}
        </StyledRowList>
      </StyledToolTip>
      <StyledToolTip text={numberOfInteractionText(pageCount, curSelectedVersionEnvData?.n_of_samples)}>
        <StyledNumberContainer>
          <StyledText text="#" type="tinyBold" />
          <StyledText text={curSelectedVersionEnvData?.n_of_samples} type="bodyBold" />
        </StyledNumberContainer>
      </StyledToolTip>
      <StyledNumberContainer>
        <StyledScoreTypeDropdownContainer onClick={handleOpenRootMenu}>
          <StyledDescriptionTitleText text={includeEstScore ? scoreWithEst : score} type="tinyBold" />
          {anchorElRootMenu ? <KeyboardArrowUp fontSize="small" /> : <KeyboardArrowDown fontSize="small" />}
        </StyledScoreTypeDropdownContainer>
        <StyledRootMenu
          onClose={() => setAnchorElRootMenu(null)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={!!anchorElRootMenu}
          anchorEl={anchorElRootMenu}
        >
          <StyledMenuItem selected={!includeEstScore} onClick={handleScoreChange.bind(null, false)}>
            <StyledDescriptionTitleText text={score} type="tinyBold" />
          </StyledMenuItem>
          <StyledMenuItem selected={includeEstScore} onClick={handleScoreChange.bind(null, true)}>
            <StyledDescriptionTitleText text={scoreWithEst} type="tinyBold" />
          </StyledMenuItem>
        </StyledRootMenu>
        <StyledText text={getPercentage(curSelectedVersionEnvData?.score)} type="bodyBold" color={scoreColor} />
      </StyledNumberContainer>
      <StyledToolTip text={noVersionDescription}>
        <StyledInfoIcon />
      </StyledToolTip>
    </StyledGapRow>
  );
};
