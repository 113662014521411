import React from 'react';

import { alpha } from '@mui/material';

import { theme } from '@/components/lib/theme';

import { PerPropertySchema } from '../services/api';
import { getUserPreferences } from './localStorage';

export const getMarkedText = (text: string, marking_details?: PerPropertySchema | undefined) => {
  if (!marking_details || marking_details.metadata?.length === 0) return text;

  const background = () => {
    try {
      return alpha(marking_details?.color || theme.palette.primary.main, 0.3);
    } catch {
      return alpha(theme.palette.primary.main, 0.3);
    }
  };

  const parts = [];
  const sortedIndexes = [...(marking_details.metadata ?? [])].sort((a, b) => a.start - b.start);

  let lastIndex = 0;

  sortedIndexes.forEach(({ start, end }, index) => {
    if (start < 0 || end < 0 || start > end || start >= text.length) {
      return;
    }

    parts.push(text.slice(lastIndex, start));
    parts.push(
      <span key={index} style={{ backgroundColor: background() }} id={index === 0 ? 'first-marked' : undefined}>
        {text.slice(start, end + 1)}
      </span>
    );

    lastIndex = end + 1;
  });

  parts.push(text.slice(lastIndex));

  // // Scroll to the marked area
  // setTimeout(() => {
  //   const firstMarkedElement = document?.getElementById('first-marked');

  //   if (firstMarkedElement) {
  //     firstMarkedElement?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  //   }
  // }, 10);

  return <>{parts}</>;
};

export const propsMarkingColor = (marking_details?: PerPropertySchema) => {
  const { darkMode } = getUserPreferences();

  const isDarkMode = Boolean(darkMode).toString();

  return isDarkMode
    ? (marking_details?.color?.includes('#') && marking_details?.color) || theme.palette.primary.main
    : theme.palette.primary.main;
};
