import { styled, Stack, IconButton } from '@mui/material';

import { StyledText, StyledButton } from '@/components/lib';

export const StyledContainer = styled(Stack)(({ theme }) => ({
  minHeight: '56px',
  flexDirection: 'row',
  padding: '4px 12px',
  borderRadius: '12px',
  marginBottom: '16px',
  alignItems: 'center',
  justifyContent: 'space-between',
  background: theme.palette.grey[100]
}));

export const StyledPaginationContainer = styled(Stack)({
  flexDirection: 'row',
  gap: '8px',
  alignItems: 'center',
  minWidth: '110px',

  '.MuiTypography-root': { margin: '0 auto', width: 'fit-content' }
});

export const StyledAnnotationCellContainer = styled(Stack)(({ theme }) => ({
  padding: '0 8px',
  cursor: 'pointer',
  borderRadius: '8px',
  maxWidth: '260px',
  marginLeft: '-12px',
  background: theme.palette.common.white,
  border: `2px solid ${theme.palette.grey[300]}`
}));

export const StyledAnnotationCellText = styled(StyledText)({
  maxWidth: '100%',
  margin: 'auto 0',
  overflow: 'hidden',
  WebkitLineClamp: '2',
  whiteSpace: 'initial',
  display: '-webkit-box',
  textOverflow: 'ellipsis',
  WebkitBoxOrient: 'vertical',
  fontWeight: 500
});

export const StyledPaginationButton = styled(StyledButton)(({ theme }) => ({
  fontWeight: 700,
  background: theme.palette.grey[300],
  color: theme.palette.grey[600],
  minWidth: '24px',
  height: '24px',
  fontSize: '20px',
  padding: '4px',
  borderRadius: '4px',

  ':hover': {
    background: theme.palette.grey[400]
  }
}));

export const StyledHeaderContainerBase = styled(Stack)(({ theme }) => ({
  gap: '16px',
  flexDirection: 'row',

  '.MuiTypography-h2': { color: theme.palette.grey[700], fontSize: '16px' },
  '.MuiSvgIcon-root': { color: theme.palette.grey[400], cursor: 'pointer' }
}));

export const StyledHeaderInteractionIdContainer = styled(Stack)({
  flexDirection: 'row',
  alignItems: 'center',
  flexWrap: 'wrap'
});

export const StyledAnnotationTitle = styled(StyledText)(({ theme }) => ({
  fontWeight: 700,
  color: theme.palette.grey[500]
}));

export const StyledHeaderContainerSecondary = styled(Stack)({
  marginLeft: 'auto',
  flexDirection: 'row',
  gap: '16px'
});

export const StyledIconButton = styled(IconButton)({
  padding: 0
});
