import React from 'react';

import { SamplesTablePopoverSortByProperty } from '@/components/Samples/SamplesTable/SamplesTablePopover/SamplesTablePopoverSortByProperty/SamplesTablePopoverSortByProperty';

import { OrderByModel, PropertyDefinitionSchema } from '@/helpers/services/api';

import { SamplesDetailsBodyPropertyType } from '../SampleDetailsBodyProperties';
import { StyledPopover } from './SampleDetailsBodyPropertiesAddPropertyPopover.styles';

export interface SampleDetailsBodyPropertiesAddPropertyPopoverProps {
  anchorEl: HTMLElement | null;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  allPropsList?: SamplesDetailsBodyPropertyType[];
  handleSubmit?: ((item: SamplesDetailsBodyPropertyType) => void) | undefined;
}

export const SampleDetailsBodyPropertiesAddPropertyPopover = (
  props: SampleDetailsBodyPropertiesAddPropertyPopoverProps
) => {
  const { allPropsList, handleSubmit, anchorEl, setAnchorEl } = props;

  const curPropertiesList = Array?.isArray(allPropsList)
    ? allPropsList?.reduce((acc: PropertyDefinitionSchema[], el: SamplesDetailsBodyPropertyType) => {
        return [
          ...acc,
          {
            property_name: el.name,
            property_type: el.type
          } as PropertyDefinitionSchema
        ];
      }, [] as PropertyDefinitionSchema[])
    : [];

  const handleApplyClick = (newKind: OrderByModel, newProperty?: string) => {
    setAnchorEl(null);

    const newProp = allPropsList?.find(el => el.name === newProperty && el.type === newKind.split('_properties')[0]);
    handleSubmit && newProp && handleSubmit(newProp);
  };

  return (
    <StyledPopover open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
      <SamplesTablePopoverSortByProperty
        model={`_properties` as OrderByModel}
        column={'' as string}
        handleApplyClick={handleApplyClick}
        propertylist={curPropertiesList || []}
      />
    </StyledPopover>
  );
};
