import { useTheme } from '@mui/material';

import { Operator } from '../services/api';

export const useScoreColor = (score?: number, condition?: Operator, threshold?: number) => {
  const theme = useTheme();

  const red = theme.palette.error.main;
  const green = theme.palette.success.main;
  const grey = theme.palette.grey[400];

  const switchCondition = (!!score || score === 0) && (!!threshold || threshold === 0) && condition;

  if (switchCondition) {
    switch (condition) {
      case Operator['LE']:
        return Number(score) <= Number(threshold) ? green : red;
      case Operator['GE']:
        return Number(score) >= Number(threshold) ? green : red;
      default:
        return grey;
    }
  } else {
    return grey;
  }
};
