import { styled, Box, ListItem } from '@mui/material';
import List from '@mui/material/List';

import { StyledInput } from '@/components/lib';

export const StyledSearchInput = styled(StyledInput)({
  width: 'calc(100% - 32px)',
  margin: '0 16px'
});

export const StyledList = styled(List)({
  height: '230px',
  overflow: 'auto',
  margin: '8px 0 0'
});

export const StyledListItem = styled(ListItem)<{ is_active: string }>(({ is_active, theme }) => ({
  background: is_active === 'true' ? theme.palette.grey[100] : 'transparent'
}));

export const StyledEmptyList = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '254px',
  padding: '16px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: theme.palette.grey[500]
}));
