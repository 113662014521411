export const constants = {
  backButtonLabel: '<',
  nextButtonLabel: '>',
  similarityLinkLabel: 'Similar Response',
  interactionIdText: (interactionIdText: string) => `User Interaction ID: ${interactionIdText}`,
  paginationText: (currentPage: number, pageCount: number) => `${currentPage + 1}/${pageCount}`,
  copy: {
    linkCopied: 'Link Copied!',
    copyLinkText: 'Copy Link'
  }
};
