import { styled, Stack, IconButton } from '@mui/material';

import { StyledContainer as DSStyledContainer } from '@/components/lib';

export const StyledContainer = styled(Stack)<{ is_big_container?: string }>(({ is_big_container, theme }) => ({
  minHeight: is_big_container === 'true' ? '600.6px' : '539px',
  height: 'auto',
  borderRadius: '12px',
  background: theme.palette.grey[100]
}));

export const StyledInputContainer = styled(DSStyledContainer)<{ is_two_items?: string }>(({ is_two_items, theme }) => ({
  gap: '0',
  height: 'auto',
  margin: '8px',
  borderRadius: 0,
  display: 'grid',
  padding: '16px 24px',
  width: 'calc(100% - 16px)',
  gridTemplateColumns: is_two_items === 'true' ? '50px auto' : '50px auto 120px',
  borderBottom: `2px solid ${theme.palette.grey[300]}`,

  '.MuiTypography-root': { whiteSpace: 'nowrap' },
  '.MuiTypography-body2': { color: theme.palette.grey[600] }
}));

export const StyledInputTextContainer = styled(Stack)({
  overflow: 'hidden',
  textOverflow: 'ellipsis'
});

export const StyledArrowContainer = styled(Stack)({
  gap: 0,
  flexDirection: 'row',
  justifyContent: 'space-between',
  position: 'relative'
});

export const StyledInputTextWrapper = styled(Stack)({
  flexDirection: 'row',
  alignItems: 'center',
  width: '80%'
});

export const StyledArrowIconButton = styled(IconButton)(({ theme }) => ({
  padding: 0,
  color: theme.palette.grey[400],
  position: 'absolute',
  left: 0,
  top: '10px'
}));

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  padding: 0,
  color: theme.palette.grey[400],
  position: 'absolute',
  right: 0,
  top: '10px'
}));

export const StyledVersionsContainer = styled(DSStyledContainer)({
  display: 'grid',
  padding: '0 8px',
  gridTemplateColumns: `repeat(2, 1fr)`,
  gap: 0
});

export const StyledNoVersionsContainer = styled(Stack)({
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '663px'
});
