import { Stack, styled, alpha } from '@mui/material';

import { StyledContainer as DSStyledContainer, StyledText } from '@/components/lib';

import { fadeInAnimation } from '@/helpers/animations';

export const StyledContainer = styled(DSStyledContainer, {
  shouldForwardProp: prop => prop !== 'reason'
})<{ first?: string; is_big_container?: string }>(({ first, is_big_container, theme }) => ({
  gap: '8px',
  borderRadius: 0,
  minHeight: is_big_container === 'true' ? '526.6px' : '465px',
  overflowY: 'auto',
  paddingRight: first === 'true' ? '32px' : '8px',
  paddingLeft: first === 'true' ? '8px' : '32px',
  animation: `${fadeInAnimation} 0.5s ease`,
  borderRight: first === 'true' ? `2px solid ${theme.palette.grey[200]}` : 'none'
}));

export const StyledVersionsContainer = styled(DSStyledContainer, {
  shouldForwardProp: prop => prop !== 'nOfVersions'
})<{ nOfVersions?: number }>(({ nOfVersions }) => ({
  display: 'grid',
  gridTemplateColumns: `repeat(${nOfVersions ?? 2}, 1fr)`
}));

export const StyledAnnotationCellContainer = styled(Stack, {
  shouldForwardProp: prop => prop !== 'reason'
})<{ reason?: boolean }>(({ reason, theme }) => ({
  height: '48px',
  padding: '0 8px',
  borderRadius: '8px',
  width: reason ? '200px' : '50px',
  cursor: reason ? 'auto' : 'pointer',
  background: theme.palette.common.white,
  border: `2px solid ${theme.palette.grey[300]}`
}));

export const StyledSelectOption = styled(StyledText, {
  shouldForwardProp: prop => prop !== 'selected' && prop !== 'show'
})<{ selected?: boolean; show?: boolean }>(({ theme, selected, show }) => ({
  height: 'auto',
  padding: '4px',
  margin: '0 4px',
  cursor: 'pointer',
  textAlign: 'center',
  overflow: 'visible',
  borderRadius: '0px',
  width: 'fit-content',
  whiteSpace: 'nowrap',
  transition: 'all 0.3s ease',
  display: show ? 'auto' : 'none',
  color: selected ? theme.palette.primary.main : theme.palette.grey[400],
  borderBottom: `2px solid ${selected ? theme.palette.primary.main : 'transparent'}`
}));

export const StyledGapRow = styled(Stack)<{ is_border_left?: string }>(({ is_border_left, theme }) => ({
  gap: '14px',
  overflowX: 'auto',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'row',
  marginBottom: '16px',
  height: '47px',
  borderLeft: is_border_left === 'true' ? `2px solid ${theme.palette.grey[200]}` : 'none'
}));

export const StyledRowList = styled(Stack)({
  gap: '4px',
  margin: '4px 0',
  overflowX: 'auto',
  alignItems: 'center',
  flexDirection: 'row',

  '.MuiInputBase-root': { width: '150px' },
  '.MuiTypography-h3': { whiteSpace: 'nowrap', width: '160px' }
});

export const StyledEmptyContainer = styled(StyledContainer)({
  width: '100%',
  height: 'auto',
  margin: 'auto',
  textAlign: 'center',
  alignItems: 'center',
  minHeight: 'auto'
});

export const StyledSubTitleText = styled(StyledText)(({ theme }) => ({
  maxWidth: '322px',
  color: theme.palette.grey[300]
}));

export const StyledTitleText = styled(StyledText)(({ theme }) => ({
  color: theme.palette.grey[400]
}));

export const StyledEmptyIconContainer = styled(Stack)(({ theme }) => ({
  background: alpha(theme.palette.primary.main, 0.2),
  width: '63px',
  height: '63px',
  borderRadius: '50%',
  marginBottom: '18px',
  alignItems: 'center',
  justifyContent: 'center'
}));
