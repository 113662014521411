import React from 'react';

import { JoinFullOutlined } from '@mui/icons-material';

import {
  StyledEmptyContainer,
  StyledEmptyIconContainer,
  StyledSubTitleText,
  StyledTitleText
} from '../SampleDetailsDialogComparisonInteraction.styles';

const constants = {
  title: 'No Similar Interaction',
  subTitle:
    'This interaction (same user_interaction_id) does not exist in this version. Change the selected version or see other interactions.'
};

const { title, subTitle } = constants;

export const NoSampleDetailsDialogComparisonInteraction = () => {
  return (
    <StyledEmptyContainer>
      <StyledEmptyIconContainer>
        <JoinFullOutlined color="primary" fontSize="large" />
      </StyledEmptyIconContainer>
      <StyledTitleText text={title} type="h3" />
      <StyledSubTitleText text={subTitle} type="body" />
    </StyledEmptyContainer>
  );
};
