import React, { useState } from 'react';

import { ArrowCircleLeft, ArrowCircleRight } from '@mui/icons-material';

import { SampleDetailsDialogBodyCard } from './SampleDetailsDialogBodyCard/SampleDetailsDialogBodyCard';
import { SampleDetailsBodyPropertiesAddPropertyPopover } from './SampleDetailsBodyPropertiesAddPropertyDialog/SampleDetailsBodyPropertiesAddPropertyPopover';

import { StyledText } from '@/components/lib';
import {
  PropertiesAddCardText,
  PropertyAddCardContainer,
  StyledArrowsContainer,
  StyledExpandLess,
  StyledExpandMore,
  StyledPropertiesContainer,
  StyledPropertiesScrollContainer,
  StyledRowContainer,
  StyledTitleDivider
} from '../SamplesDetailsBodyItem.styles';

import { UpdatePropertySchema } from '@/helpers/utils/deepCloneUpdates';
import { handleFindElement } from './SampleDetailsBodyProperties.helpers';
import { getStorageItem, storageKeys } from '@/helpers/utils/localStorage';
import {
  ReadPropertyConfigSchema,
  InputPropertiesSchema,
  InteractionSchemaCustomProperties,
  InteractionSchemaLlmProperties,
  OutputPropertiesSchema,
  PerPropertySchema,
  PropertyType,
  InteractionSchemaLlmPropertiesReasons,
  PropertyDefinitionSchema,
  InteractionSchemaOutputProperties
} from '@/helpers/services/api';

import { constants } from './sampleDetailsBodyProperties.constants';
import { SelectedColumnType } from '@/components/Samples/Samples.types';

const { propertiesTitle, addCardLabel, limit } = constants;

export interface SamplesDetailsBodyPropertyType {
  name: string;
  value: number | string;
  type: 'input' | 'output' | 'custom' | 'llm' | 'garak';
}

export interface SamplesDetailsBodyAllPropertiesType {
  input: InputPropertiesSchema | null;
  output: OutputPropertiesSchema | null;
  custom: InteractionSchemaCustomProperties | null;
  llm: InteractionSchemaLlmProperties | null;
  garak: InteractionSchemaLlmProperties | null;
}

interface SamplesDetailsBodyPropertiesProps {
  appId?: number;
  noTitle?: boolean;
  isPentest?: boolean;
  isComparison?: boolean;
  showProperties: boolean;
  orderingProps?: boolean;
  propertyToMark?: string;
  isPropertyOpen?: boolean;
  userInteractionId?: string;
  switchToTranslated?: boolean;
  isVersionComparison?: boolean;
  ignoreReasoningTooltip?: boolean;
  selectedColumns?: SelectedColumnType[];
  allMarkingDetails?: PerPropertySchema[];
  properties: SamplesDetailsBodyAllPropertiesType;
  allPropsList?: SamplesDetailsBodyPropertyType[];
  listOfProperties?: ReadPropertyConfigSchema[];
  comparisonProps?: SamplesDetailsBodyPropertyType[];
  llmReasons?: InteractionSchemaLlmPropertiesReasons;
  setSection?: (section: string) => void;
  setIsTranslation?: (isTranslation: boolean) => void;
  setShowProperties?: (showProperties: boolean) => void;
  getPropertyInfo?: (name: string) => PropertyDefinitionSchema;
  setPropertyToMark?: React.Dispatch<React.SetStateAction<string>>;
  setIsPropertyOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  handleAddProperty?: (item: SamplesDetailsBodyPropertyType) => void;
  handleDeleteProperty?: (item: SamplesDetailsBodyPropertyType) => void;
  updateProperty?: (updateData: UpdatePropertySchema) => void;
}

export const SampleDetailsBodyProperties = (props: SamplesDetailsBodyPropertiesProps) => {
  const {
    appId,
    noTitle,
    isPentest,
    properties,
    llmReasons,
    allPropsList,
    isComparison,
    orderingProps,
    isPropertyOpen,
    showProperties,
    propertyToMark,
    comparisonProps,
    selectedColumns,
    listOfProperties,
    allMarkingDetails,
    userInteractionId,
    switchToTranslated,
    isVersionComparison,
    ignoreReasoningTooltip,
    setSection,
    updateProperty,
    getPropertyInfo,
    setIsTranslation,
    handleAddProperty,
    setPropertyToMark,
    setIsPropertyOpen,
    setShowProperties,
    handleDeleteProperty
  } = props;

  const [isExpand, setIsExpand] = useState(true);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const propertiesStorage = getStorageItem(storageKeys.propFilters);

  const noProperties = !properties.custom && !properties.llm && !properties.input && !properties.output;

  if (noProperties || (isComparison && !allPropsList?.length && !comparisonProps?.length)) {
    return <></>;
  }

  const handleChangeShowProperties = () => setShowProperties && setShowProperties(!showProperties);

  const isCurComparison = isComparison && comparisonProps;

  const renderSampleDetailsCard = (key: string, value: number | string | null, kind: PropertyType) => {
    const reason = kind === 'llm' ? llmReasons?.[key as keyof InputPropertiesSchema] : '';
    const formattedValue = typeof value === 'number' ? +value.toFixed(2) || 0 : value || 'N/A';

    const item: SamplesDetailsBodyPropertyType = {
      name: key,
      value: value || '',
      type: kind
    };

    return (
      <SampleDetailsDialogBodyCard
        kind={kind}
        reason={reason as string}
        key={`${key}-${kind}`}
        propertyToMark={propertyToMark}
        userInteractionId={userInteractionId}
        allMarkingDetails={allMarkingDetails}
        switchToTranslated={switchToTranslated}
        card={{ title: key, value: formattedValue }}
        ignoreReasoningTooltip={ignoreReasoningTooltip}
        updateProperty={updateProperty}
        propertyDefinition={getPropertyInfo && getPropertyInfo(key)}
        style={{
          padding: isComparison ? '8px' : '8px 16px',
          maxWidth: isComparison ? '163px' : 'auto',
          minWidth: isComparison ? '163px' : 'auto'
        }}
        {...(handleDeleteProperty && { handleDelete: handleDeleteProperty.bind(null, item) })}
        setSection={setSection}
        setIsTranslation={setIsTranslation}
        setPropertyToMark={setPropertyToMark}
      />
    );
  };

  const handleBuildCurProperties = (
    propertiesList: InputPropertiesSchema | InteractionSchemaLlmProperties | InteractionSchemaOutputProperties | null,
    type: string
  ) => {
    return propertiesList
      ? Object.entries(propertiesList).map(([key, value]) => ({
          name: key,
          value,
          type: type as 'input' | 'output' | 'custom' | 'llm'
        }))
      : [];
  };

  const allPropsArr: SamplesDetailsBodyPropertyType[] = orderingProps
    ? [
        ...handleBuildCurProperties(properties.input, 'input'),
        ...handleBuildCurProperties(properties.output, 'output'),
        ...handleBuildCurProperties(properties.llm, 'llm'),
        ...handleBuildCurProperties(properties.custom, 'custom'),
        ...(isPentest ? [...handleBuildCurProperties(properties.garak, 'garak')] : [])
      ]
    : [];

  const propsFromStorage = orderingProps && appId && propertiesStorage?.[appId]?.prop_filters;

  const curSelectedColumns: SamplesDetailsBodyPropertyType[] = Array.isArray(selectedColumns)
    ? selectedColumns?.reduce((acc: SamplesDetailsBodyPropertyType[], el) => {
        const findCurElemInAllProp = handleFindElement(allPropsArr, {
          text_property: el.propertyName || '',
          kind: el.propertyType?.toLowerCase() || ''
        });

        if (findCurElemInAllProp && findCurElemInAllProp.value !== null) {
          return [...acc, findCurElemInAllProp];
        }

        return [...acc];
      }, [] as SamplesDetailsBodyPropertyType[])
    : [];

  const selectedProps: SamplesDetailsBodyPropertyType[] = Array.isArray(propsFromStorage)
    ? propsFromStorage?.reduce((acc: SamplesDetailsBodyPropertyType[], el: { text_property: string; kind: string }) => {
        const findCurElemInAllProp = handleFindElement(allPropsArr, el);
        const findCurElemInSelectedColumns = handleFindElement(curSelectedColumns, el);

        if (findCurElemInAllProp && findCurElemInAllProp.value !== null && !findCurElemInSelectedColumns) {
          return [...acc, findCurElemInAllProp];
        }

        return [...acc];
      }, [] as SamplesDetailsBodyPropertyType[])
    : [];

  const curSelectedProps = [...(curSelectedColumns || []), ...(selectedProps || [])];
  const curListOfProperties: SamplesDetailsBodyPropertyType[] = Array.isArray(listOfProperties)
    ? listOfProperties.reduce((acc, el) => {
        if (curSelectedProps.length && !handleFindElement(curSelectedProps, el)) {
          const findCurElemInAllProp = handleFindElement(allPropsArr, el);

          return [
            ...acc,
            // Hide null props condition findCurElemInAllProp?.value !== null
            ...(findCurElemInAllProp && findCurElemInAllProp?.value !== null ? [findCurElemInAllProp] : [])
          ];
        } else if (orderingProps && !curSelectedProps.length) {
          const curElData = handleFindElement(allPropsArr, el);

          // Hide null props condition curElData?.value !== null
          return [...acc, ...(curElData && curElData?.value !== null ? [curElData] : [])];
        }

        return [...acc];
      }, [] as SamplesDetailsBodyPropertyType[])
    : ([] as SamplesDetailsBodyPropertyType[]);

  const handleRenderOrderedProps = (propertiesList: SamplesDetailsBodyPropertyType[]) =>
    propertiesList?.length > 0 &&
    propertiesList.map(({ name, value, type }) => renderSampleDetailsCard(name, value, PropertyType[type]));

  const handleRenderNotOrderedProps = (
    propertiesList:
      | InputPropertiesSchema
      | InteractionSchemaCustomProperties
      | InteractionSchemaOutputProperties
      | null,
    type: 'input' | 'output' | 'custom' | 'llm'
  ) =>
    propertiesList &&
    Object.entries(propertiesList).map(([key, value]) => {
      if (Array.isArray(curSelectedProps) && curSelectedProps.find(el => el.name === key && el.type === type)) {
        return null;
      }

      return renderSampleDetailsCard(key, value, PropertyType[type]);
    });

  const isDefaultProps = !listOfProperties && !isComparison;

  const openAddDialog = (e: { currentTarget: HTMLElement | null }) => setAnchorEl(e.currentTarget);

  const handleExpandChange = () => {
    if (setIsPropertyOpen && isVersionComparison) {
      setIsPropertyOpen(!isPropertyOpen);
    } else {
      setIsExpand(!isExpand);
    }
  };

  const curExpand = isVersionComparison ? isPropertyOpen : isExpand;

  return (
    <StyledPropertiesContainer row={isComparison}>
      {!isComparison && (
        <StyledArrowsContainer onClick={handleChangeShowProperties}>
          {showProperties ? <ArrowCircleRight /> : <ArrowCircleLeft />}
        </StyledArrowsContainer>
      )}
      {showProperties && (
        <>
          {isComparison && !noTitle ? (
            <StyledRowContainer>
              <StyledText text={propertiesTitle(isComparison)} type="small" />
              <StyledTitleDivider />
              {curExpand ? (
                <StyledExpandLess onClick={handleExpandChange} />
              ) : (
                <StyledExpandMore onClick={handleExpandChange} />
              )}
            </StyledRowContainer>
          ) : (
            !noTitle && <StyledText text={propertiesTitle(isComparison)} type="h3" />
          )}
          <StyledPropertiesScrollContainer row={isComparison}>
            {isCurComparison && curExpand ? (
              <>
                {handleRenderOrderedProps(comparisonProps)}
                {comparisonProps?.length < limit && handleAddProperty && Boolean(allPropsList?.length) && (
                  <>
                    <PropertyAddCardContainer type="card" onClick={openAddDialog} data-testid="AddPropertyButton">
                      <PropertiesAddCardText type="body" text={addCardLabel} />
                    </PropertyAddCardContainer>
                    <SampleDetailsBodyPropertiesAddPropertyPopover
                      anchorEl={anchorEl}
                      setAnchorEl={setAnchorEl}
                      allPropsList={allPropsList}
                      handleSubmit={handleAddProperty}
                    />
                  </>
                )}
              </>
            ) : (
              <>
                {orderingProps && handleRenderOrderedProps(curSelectedProps)}
                {handleRenderOrderedProps(curListOfProperties)}
                {isDefaultProps && handleRenderNotOrderedProps(properties.input, 'input')}
                {isDefaultProps && handleRenderNotOrderedProps(properties.output, 'output')}
                {isDefaultProps && handleRenderNotOrderedProps(properties.custom, 'custom')}
                {isDefaultProps && handleRenderNotOrderedProps(properties.llm, 'llm')}
              </>
            )}
          </StyledPropertiesScrollContainer>
        </>
      )}
    </StyledPropertiesContainer>
  );
};
